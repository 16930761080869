<template>
  <div>
    <general-table
      ref="waffarhaTransactionsTable"
      :api-url="apiUrl"
      :columns="fields"
      :view-content="true"
      type="page"
      :edit-content="false"
      :delete-content="false"
      :search-box="true"
      :selectable="false"
      :export-table="false"
      guard="waffarha"
      viw-component="view-waffarha-transaction"
    >
      <template #export>
        <div>
          <b-button
            v-if="!loadExport"
            variant="warning"
            class="btn-icon mx-1"
            @click="exportToCSV"
          >
            <feather-icon icon="ExternalLinkIcon" />
            Export
          </b-button>
          <b-button
            v-else
            variant="warning"
            class="btn-icon mx-1"
          >
            <b-spinner
              small
              type="grow"
            />
            Exporting...
          </b-button>
        </div>

      </template>
      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>
    </general-table>

  </div>
</template>

<script>
import axios from 'axios'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
// import AdminDashboardTable from '@/views/dashboard/admin-dashboard/components/AdminDashboardTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const report = ref([])
    const { getValidationState } = formValidation()
    const loadExport = false
    const amount = ref('')
    const addBalanceAmount = ref('')
    const loaderEditBalance = false
    const loaderAddBalance = false

    const fields = [
      { key: 'id', label: '#', sortable: true },
      { key: 'order_id', label: 'Order Id' },
      { key: 'amount', label: 'Amount' },
      { key: 'actions', label: 'Action' },

    ]
    const errorMsg = ref('')

    return {
      amount,
      loaderEditBalance,
      loaderAddBalance,
      addBalanceAmount,
      getValidationState,
      timeAgo,
      errorMsg,
      fields,
      loadExport,
      report,
    }
  },
  computed: {
    apiUrl() {
      const base = 'waffarha_transaction'

      return base
    },

  },
  methods: {
    exportToCSV() {
      this.loadExport = true
      const self = this
      const promis = axios.get('waffarha_transactions', { params: { limit: self.perPage, export: 'csv' }, responseType: 'arraybuffer' })
      return promis.then(res => {
        if (res.status === 200) {
          const blob = new Blob([res.data], { type: 'application/csv' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = 'exported.csv'
          link.click()
        }
      }).finally(() => {
        this.loadExport = false
      })
    },
  },
}
</script>

<style>

</style>
